import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'transaction-history',
    loadChildren: () => import('./pages/transaction-history/transaction-history.module').then(m => m.TransactionHistoryPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountPageModule)
  },
  {
    path: 'boledo',
    loadChildren: () => import('./pages/boledo/boledo.module').then(m => m.BoledoPageModule)
  },
  {
    path: 'spend-points',
    loadChildren: () => import('./pages/spend-points/spend-points.module').then(m => m.SpendPointsPageModule)
  },
  {
    path: 'redeem-points',
    loadChildren: () => import('./pages/redeem-points/redeem-points.module').then(m => m.RedeemPointsPageModule)
  },
  {
    path: 'cashier-screen',
    loadChildren: () => import('./pages/cashier-screen/cashier-screen.module').then(m => m.CashierScreenPageModule)
  },
  {
    path: 'boledo-details',
    loadChildren: () => import('./pages/boledo-details/boledo-details.module').then(m => m.BoledoDetailsPageModule)
  },
  {
    path: 'transfer-points',
    loadChildren: () => import('./pages/transfer-points/transfer-points.module').then(m => m.TransferPointsPageModule)
  },
  {
    path: 'customer-search-modal',
    loadChildren: () => import('./pages/customer-search-modal/customer-search-modal.module').then(m => m.CustomerSearchModalPageModule)
  },
  {
    path: 'customer-pin',
    loadChildren: () => import('./pages/customer-pin/customer-pin.module').then(m => m.CustomerPinPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
